import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/SEO"
import SimpleBanner from "../../components/SimpleBanner/SimpleBanner"

const Container = styled.div`
  max-width: 80ch;
  margin: 80px auto 0px;
  text-align: left;
  padding: 1em;

  h2 {
    color: var(--primary);
    font-size: var(--h2);
  }

  .link-container {
    color: var(--primary);
    font-weight: bold;
  }

  span {
    color: var(--primary);
  }

  a {
    color: white;
  }

  p {
    line-height: 1.6em;
  }
`

const band_person = () => {
  return (
    <>
      <Seo title="Hubert Sobiecki" />
      <Layout>
        <SimpleBanner title="Hubert Sobiecki">
          <StaticImage
            className="banner__image"
            src="../../images/band/hubert.png"
          />
        </SimpleBanner>
        <Container>
          <p>
            Saksofonista z klasycznym warsztatem i bogatym doświadczeniem.
            Ekspresywny, nieustannie doskonalący swoje umiejętności
            improwizacji. Magister Sztuki Jazzowej i muzyki estradowej ukończony
            na Akademi Muzycznej im. Karola Szymanowskiego w Katowicach. Swoje
            umiejętności improwizacji rozwijał podczas warsztatów z
            saksofonistami światowego formatu, takimi jak Eric Marienthal i
            Walter Smith III.
          </p>
          <p>
            Współpracował m.in. z Mrozu, Zbigniewem Namysłowskim, Krzysztofem
            Herdzinem i wieloma innymi. Huberta można również spotkać w
            programie Jaka to melodia. W 2017 roku zdobył nagrodę Najlepszego
            Instrumentalisty podczas 22 Big Band Fastiwal w Nowym Tomyślu.
            Inspiruje się zarówno klasykami takimi jak John Caltrane, Charlie
            Parker czy Cannonball Adderley, jak i wirtuozami współczesnego jazzu
            : Stefano di Battista, Kenny Garret czy Braxton Cook.
          </p>
        </Container>
      </Layout>
    </>
  )
}

export default band_person
